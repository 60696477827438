import { datadogRum } from "@datadog/browser-rum";
import getEnv from "~/utils/env";
import { name as service, version } from "package.json";
import { isbot as checkIfIsBot } from "isbot";

const isStagingOrProd = ["staging", "production"].includes(
  getEnv().REACT_APP_ENV!,
);
if (typeof document !== "undefined" && isStagingOrProd) {
  const isBot = checkIfIsBot(window.navigator.userAgent);
  const isProd = getEnv().REACT_APP_ENV! === "production";
  let sessionReplaySampleRate = 0;

  if (isProd && !isBot) {
    sessionReplaySampleRate = 50;
  }

  datadogRum.init({
    applicationId: getEnv().REACT_APP_DATADOG_APP_ID!,
    clientToken: getEnv().REACT_APP_DATADOG_CLIENT_TOKEN!,
    site: "datadoghq.com",
    service,
    version,
    env: getEnv().REACT_APP_ENV,
    enableExperimentalFeatures: ["feature_flags"],
    sessionSampleRate: isBot ? 0 : 100,
    sessionReplaySampleRate,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackViewsManually: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

// Render nothing - this component is only included so that the init code
// above will run client-side
export const DatadogInit = () => null;
