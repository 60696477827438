import { createPortal } from "react-dom";
import {
  type MetaFunction,
  json,
  LoaderFunctionArgs,
  ActionFunctionArgs,
  redirect,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { useChangeLanguage } from "remix-i18next";
import { remixI18next } from "./i18n/i18next.server";
import { token } from "ui/tokens";
import getEnv from "~/utils/env";
import { AppScripts } from "./scripts";
import { ClientOnly } from "~/components/ClientOnly";

import { setI18nLocale } from "app/i18n";
import { updateUserLanguage } from "./services/updateProfile.server";
import { getSession } from "./utils/sessions.server";

export const meta: MetaFunction = () => [
  { title: "Gartner Digital Markets", charset: "utf-8" },
];

const tmpAddCompoundFormat = {
  de: "de-DE",
  en: "en-US",
  es: "es-ES",
  fr: "fr-FR",
  it: "it-IT",
  ja: "ja-JP",
  nl: "nl-NL",
  pt: "pt-BR",
};
export const loader = async ({ request }: LoaderFunctionArgs) => {
  const locale = await remixI18next.getLocale(request);
  const tmpLocale =
    tmpAddCompoundFormat?.[locale as keyof typeof tmpAddCompoundFormat] ||
    locale;
  const headers = await setI18nLocale(tmpLocale);

  const env = getEnv();

  return json(
    {
      locale: tmpLocale,
      env: {
        REACT_APP_ENV: env.REACT_APP_ENV,
        REACT_APP_VP_BASE_URL: env.REACT_APP_VP_BASE_URL,
        REACT_APP_VP_API_BASE_URL: env.REACT_APP_VP_API_BASE_URL,
        REACT_APP_VP_API_KEY: env.REACT_APP_VP_API_KEY,
        REACT_APP_GTM_ID: env.REACT_APP_GTM_ID,
        REACT_APP_GTM_AUTH: env.REACT_APP_GTM_AUTH,
        REACT_APP_GTM_PREVIEW: env.REACT_APP_GTM_PREVIEW,
        REACT_APP_PENDO_API_KEY: env.REACT_APP_PENDO_API_KEY,
        REACT_APP_LAUNCH_DARKLY_CLIENT_ID:
          env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
        REACT_APP_CONTENTFUL_SPACE: env.REACT_APP_CONTENTFUL_SPACE,
        REACT_APP_CONTENTFUL_ENVIRONMENT: env.REACT_APP_CONTENTFUL_ENVIRONMENT,
        REACT_APP_CONTENTFUL_ACCESS_TOKEN:
          env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN:
          env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
        REACT_APP_DATADOG_APP_ID: env.REACT_APP_DATADOG_APP_ID,
        REACT_APP_DATADOG_CLIENT_TOKEN: env.REACT_APP_DATADOG_CLIENT_TOKEN,
        REACT_APP_MSW_MOCKED: env.REACT_APP_MSW_MOCKED,
        AXIOS_LOGGER: env.AXIOS_LOGGER,
        INTERNAL_API_BASE_URL: env.INTERNAL_API_BASE_URL,
      },
    },
    { headers },
  );
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));

  let formData;
  try {
    formData = await request.formData();
  } catch (e) {
    return null;
  }

  const intent = String(formData.get("intent"));
  if (intent === "changeLanguage") {
    const locale = String(formData.get("locale"));
    const headers = await setI18nLocale(locale);
    const redirectURL = String(request.headers.get("Referer")) || "/";

    if (session.get("accessToken")) {
      await updateUserLanguage(request, locale);
    }

    return redirect(redirectURL, { headers });
  }
  return null;
};

export function Head() {
  return (
    <>
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="theme-color" content={token("colors.black")} />
      <meta charSet="UTF-8" />
      <Meta />
      <Links />
      <AppScripts />
    </>
  );
}

export default function App() {
  const { locale, env } = useLoaderData<typeof loader>();

  useChangeLanguage(locale);

  return (
    <>
      <ClientOnly>{() => createPortal(<Head />, document.head)}</ClientOnly>
      <Outlet />
      <ScrollRestoration />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(env)}`,
        }}
      />
      <Scripts />
      <div id="portals" />
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  // temporary fix for caching javascript files in each release
  if (error instanceof Error) {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      window.location.reload();
    }
  }

  return null;
}
